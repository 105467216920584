import { useTranslation } from "gatsby-plugin-react-i18next";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import DEFI from "assets/images/DEFI-token.svg";
import PageLayout from "components/PageLayout";
import { store } from "store";

import * as S from "./styles";
import TokenCard from "./TokenCard";
import Tokenomics from "./Tokenomics";

const list = [
  {
    caption: "Public sale (sale ended)",
    value: "300,000"
  },
  {
    caption: "Private sale I (sale ended)",
    value: "400,000"
  },
  {
    caption: "WOWswap migration",
    value: "1,000,000"
  },
  {
    caption: "Private sale II",
    value: "3,000,000"
  },
  {
    caption: "VC marketing partners",
    value: "500,000"
  },
  {
    caption: "Team and contributors",
    value: "2,000,000"
  },
  {
    caption: "Marketing on CEXes",
    value: "1,200,000"
  },
  {
    caption: "Initial DEX liquidity",
    value: "500,000"
  },
  {
    caption: "Incentives for traders",
    value: "1,100,000"
  }
];

const ToWowHolders = observer(() => {
  const { allocations, getHistory } = store.saleHistory;
  const { t } = useTranslation();

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  return (
    <PageLayout>
      <S.MainSection>
        <S.Header>
          <DEFI />
          <S.Title>{t("Token distribution")}</S.Title>
        </S.Header>

        <S.DistributionSection>
          <TokenCard />
          <S.ListCard>
            <S.List>
              {list.map((el) => (
                <S.Item key={el.caption}>
                  <S.ItemCaption>{el.caption}</S.ItemCaption>
                  <S.ItemValue>{el.value}</S.ItemValue>
                </S.Item>
              ))}
            </S.List>
          </S.ListCard>

          {/* <S.Container>
            <S.Sale>
              <S.PublicSale>
                <S.EventHeader>
                  <S.Detail>
                    {t("$DEFI price rises every {{tokensNumber}} tokens sold", {
                      tokensNumber: "50,000"
                    })}
                  </S.Detail>
                </S.EventHeader>

                <S.EventBody>
                  <S.DateTime>
                    <S.Text>
                      {MAX_USD_ALLOCATION_HUMANLY} {t("$DEFI for $USD Stablecoins")}
                    </S.Text>
                    <div>
                      {t("Current price:")} <b>1 DEFI</b> | {t("per")} <b>{currentPrice} USD</b>
                    </div>
                  </S.DateTime>
                  <S.Event>{t("We accept different stablecoins")}</S.Event>
                </S.EventBody>

                <S.EventBody>
                  <S.DateTime>
                    <S.Text>
                      {MAX_WOW_ALLOCATION_HUMANLY} {t("$DEFI for $WOW holders")}
                    </S.Text>
                    <div>
                      {t("Current price:")} <b>1 DEFI</b> | {t("per")} <b>{currentPrice} WOW</b>
                    </div>
                  </S.DateTime>
                  <S.Event>{t("You can also buy $DEFI with $WOW tokens")}</S.Event>
                </S.EventBody>

                <S.EventBody>
                  <S.DateTime>
                    <S.Text>
                      {MAX_ONEINCH_ALLOCATION_HUMANLY} {t("$DEFI for $1INCH holders")}
                    </S.Text>
                    <div>
                      {t("Current price:")} <b>1 DEFI</b> | {t("per")} <b>{currentPrice} 1INCH</b>
                    </div>
                  </S.DateTime>
                  <S.Event>{t("Additional allocation is created for $1INCH holders")}</S.Event>
                </S.EventBody>
              </S.PublicSale>
            </S.Sale>
          </S.Container> */}
        </S.DistributionSection>

        {/* <S.TokenomicsSection>
          <Tokenomics />
        </S.TokenomicsSection> */}
      </S.MainSection>
    </PageLayout>
  );
});

export default ToWowHolders;
