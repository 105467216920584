import styled from "styled-components";

import Card from "components/Card";

import InfoCard from "../../components/InfoCard";

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 23px;

  @media (max-width: 768px) {
    /* padding-bottom: 200px; */
  }

  @media (max-width: 1020px) {
    grid-row-gap: 20px;
  }
`;

export const Title = styled.div`
  font-size: 88px;
  line-height: 93px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  word-break: break-word;

  @media (max-width: 1020px) {
    font-size: 50px;
    line-height: 53px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 6px;
`;

export const Header = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 22px;

  @media (max-width: 768px) {
    flex-direction: column;

    svg {
      width: 60px;
      margin-bottom: 10px;
    }
  }
`;

export const DistributionSection = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 44px;
  margin-bottom: 116px;

  @media (max-width: 1020px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    grid-row-gap: 20px;
  }
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

export const TextBold = styled.span`
  font-weight: bold;
  color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 26px;

  @media (max-width: 1020px) {
    grid-row-gap: 20px;
  }

  animation-name: blurin;
  animation-duration: 2s;

  @keyframes blurin {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
`;

export const Sale = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 12px;
`;

export const Date = styled.div`
  padding: 5px 13px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  background: linear-gradient(
    282.57deg,
    rgba(190, 5, 255, 0.3) -5.58%,
    rgba(7, 76, 255, 0.3) 99.41%
  );

  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
`;

export const EventHeader = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;

  @media (max-width: 1020px) {
    display: flex;
    flex-direction: column;

    font-size: 24px;
    line-height: 26px;
  }
`;

export const EventBody = styled(Row)`
  @media (max-width: 1020px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 6px;
  }
`;

export const Event = styled.div`
  font-size: 15px;
  line-height: 140%;
  color: #90a3de;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }

  b {
    color: #fff;
  }
`;

export const Detail = styled.span`
  font-size: 18px;
  line-height: 19px;

  @media (max-width: 1020px) {
    font-size: 15px;
    line-height: 140%;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const PublicSale = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
`;

export const InfoCards = styled(Row)`
  align-items: stretch;

  @media (max-width: 1020px) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
  }
`;

export const ExtraInfoCard = styled(InfoCard)`
  max-width: 305px;

  @media (max-width: 1020px) {
    max-width: 600px;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
`;

export const DateTime = styled(Date)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 5px;

  height: 46px;
  width: 100%;
  min-width: 175px;
  max-width: 305px;

  @media (max-width: 1280px) {
    max-width: 299px;
  }

  @media (max-width: 1275px) {
    max-width: 294px;
  }

  @media (max-width: 1272px) {
    max-width: 290px;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const InfoCardHeader = styled.h2`
  padding: 0;
  margin: 0;

  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  color: #ffffff;
`;

export const InfoCardBody = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 12px;
`;

export const PublicSaleInfoRow = styled(Row)`
  flex-wrap: wrap;
`;

export const Formula = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 6px;

  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  color: #ffffff;
`;

export const Operand = styled.div``;

export const OperandsDiv = styled.div`
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 6px;
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #ffffff;
`;

export const TokenomicsSection = styled.div`
  width: 100%;
`;

export const ListCard = styled(Card)`
  width: 100vw;
  max-width: 440px;

  @media (max-width: 1020px) {
    max-width: 100%;
    padding: 20px;
  }
`;

export const List = styled.div``;

export const Item = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const ItemCaption = styled.div``;

export const ItemValue = styled.div`
  margin-left: 20px;
  font-weight: bold;
`;
