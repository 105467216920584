import copy from "copy-to-clipboard";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";

import * as S from "./styles";

const DEFI_ADDRESS = "0x7caad772532339af66d886520b8C546f6758ee4d";

const shortDEFIAddress = DEFI_ADDRESS.slice(0, 5) + "..." + DEFI_ADDRESS.slice(-5);

const TokenCard = () => {
  const { t } = useTranslation();

  return (
    <S.TokenCard>
      <S.TokenInfo>
        <S.InfoLabel>{t("Name:")}</S.InfoLabel>
        <S.InfoText>Decentralized Finance Token (DEFI)</S.InfoText>
      </S.TokenInfo>

      <S.TokenInfo>
        <S.InfoLabel>{t("ERC-20 contract:")}</S.InfoLabel>
        <S.Contract>
          <S.InfoText>
            <S.ContractAddress visibility="desktop">{DEFI_ADDRESS}</S.ContractAddress>
            <S.ContractAddress visibility="mobile">{shortDEFIAddress}</S.ContractAddress>
          </S.InfoText>
          <S.ExternalLink href={`https://etherscan.io/token/${DEFI_ADDRESS}`} target="_blank">
            <S.CopyClipboardSvg onClick={() => copy(DEFI_ADDRESS)} />
          </S.ExternalLink>
        </S.Contract>
      </S.TokenInfo>

      <S.TokenInfo>
        <S.InfoLabel>{t("Total supply:")}</S.InfoLabel>
        <S.InfoText>10 {t("million tokens")}</S.InfoText>
      </S.TokenInfo>

      <S.TokenInfo>
        <S.InfoLabel>{t("Token utility:")}</S.InfoLabel>
        <S.InfoText>
          {t(
            "Protocol governance, unlocking of extra features, subscription discounts and bonuses."
          )}
        </S.InfoText>
      </S.TokenInfo>
    </S.TokenCard>
  );
};

export default TokenCard;
