import styled from "styled-components";

import CopyClipboard from "../../../assets/images/copy.svg";
import Card from "../../../components/Card";

export const TokenCard = styled(Card)`
  max-width: 440px;
  grid-row-gap: 30px;

  @media (max-width: 1020px) {
    padding: 20px;
    grid-row-gap: 12px;
    max-width: none;
  }
`;

export const TokenInfo = styled.section`
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
`;

export const InfoLabel = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 16px;
  color: #90a3de;
`;

export const InfoText = styled.p`
  margin: 0;
  padding: 0;

  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
`;

export const TextBold = styled.span`
  font-weight: bold;
`;
export const Contract = styled.span`
  display: flex;
  flex-direction: row;
  grid-column-gap: 6px;
`;

export const ContractAddress = styled.span<{ visibility: "mobile" | "desktop" }>`
  display: ${({ visibility }) => (visibility === "mobile" ? "none" : "inline")};

  @media (max-width: 768px) {
    display: ${({ visibility }) => (visibility === "desktop" ? "none" : "inline")};
  }
`;

export const ExternalLink = styled.a``;

export const CopyClipboardSvg = styled(CopyClipboard)`
  cursor: pointer;

  :hover {
    path,
    rect {
      fill: #90a3de;
    }
  }

  :active {
    path,
    rect {
      fill: white;
    }
  }
`;
